<template>
  <div>
    <Transition
      name="fade"
      mode="out-in"
      appear>
      <div
        v-if="!styleProfileComplete"
        class="mx-auto text-center mt-5">
        <h4>
          It's time to complete your <span class="semi-bold">Style Profile.</span>
        </h4>
        <p>
          This 3-minute quiz helps our stylish algorithm recommend pieces that fit your shape and unique personal style.
        </p>
        <BaseButton
          class="mt-1"
          text="Complete Style Profile"
          @click="continueStyleQuiz"/>
      </div>
      <div
        v-else
        class="container-fluid">
        <DownloadApp v-if="showDownloadAppBanner"/>
        <div class="row">
          <h2 class="text-center section-headline">
            Our Picks for You
          </h2>
        </div>
        <div
          v-if="sectionsLoaded"
          class="row row-gap-xl">
          <template v-for="section in sections">
            <MyClosetSectionPreview
              v-if="section.source != 'stylist'"
              :key="section.id"
              :section-id="section.id"
              class="col-12"/>
          </template>
          <FeaturedSections class="mb-4"/>
          <MyClosetChatPrompt class="col-12"/>
          <ClosetExploreSection/>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { computed, onActivated, onBeforeMount, provide, ref, watch } from 'vue'
import useAnalytics from '@shared/composables/analytics.js'
import MyClosetSectionPreview from './MyClosetSectionPreview.vue'
import MyClosetChatPrompt from './MyClosetChatPrompt.vue'
import DownloadApp from '@/components/global/DownloadApp.vue'
import BaseButton from '@shared/components/ADORN/BaseButton'
import FeaturedSections from './FeaturedSections.vue'
import ClosetExploreSection from './ClosetExploreSection.vue'
import logger from '@/logger'
import { useSignUpExperiments } from '@shared/composables/experiment.js'
import { useFedExOptInStore } from '@/stores/fedExOptIn.js'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@shared/stores/client.js'
import { useClosetStore } from '@shared/stores/closet.js'

// Injected in MyClosetSectionPreview
provide('dedupeKey', `dd-${Math.random()}`)

const { track } = useAnalytics()

const clientStore = useClientStore()
const { styleProfileComplete, selectedPackages, showMobileBanner } = storeToRefs(clientStore)
const { getClient } = clientStore

const closet = useClosetStore()
const { closetSections: sections, sectionsLoaded, initialDataLoading } = storeToRefs(closet)
const { resetFilters, resetDetailView } = closet

const showDownloadAppBanner = computed(() => showMobileBanner.value && selectedPackages.value > 0)

const { initializeFedExInfo, checkFedExModals } = useFedExOptInStore()

onBeforeMount(() => {
  // It's possible to land here without all the client
  // data being loaded if the user bails out of sign-up.
  if (!sectionsLoaded.value && !initialDataLoading.value && styleProfileComplete.value) {
    getClient()
  }
})

onActivated(() => {
  logger.info('MyCloset onActivated: styleProfileComplete is ', styleProfileComplete.value)
  if (sectionsLoaded.value) {
    sections.value.forEach(section => {
      if (section.detailView?.length &&
        (section.detailView.length !== section.length ||
          section.sort !== section.detailView.sort ||
          JSON.stringify(section.styleColors) !== JSON.stringify(section.detailView.styleColors))) {
        // Reset the detail views to match the base section state
        resetFilters(section)
        resetDetailView(section.id)
      }
    })
  }
  initializeFedExInfo()
  checkFedExModals()
})

const segmentSent = ref(false)
watch(() => styleProfileComplete.value && sectionsLoaded.value, (value) => {
  if (value && !segmentSent.value) {
    const sectionNames = []
    const sectionIds = []
    const sectionItems = {}
    for (const i in sections.value) {
      sectionNames.push(sections.value[i].name)
      sectionIds.push(sections.value[i].id)
      sectionItems[sections.value[i].id] = sections.value[i].styleColors
    }

    track('Viewed Closet', {
      sectionNames: sectionNames,
      sectionIds: sectionIds,
      styleProfileComplete: styleProfileComplete.value
    })

    segmentSent.value = true
  }
})

const router = useRouter()
const { signUpPhase3 } = useSignUpExperiments()
function continueStyleQuiz () {
  track('Clicked Continue Style Quiz', {
    styleProfileComplete: styleProfileComplete.value
  })
  if (signUpPhase3.value) {
    router.push({ name: 'sign-up-style-quiz' })
  } else {
    router.push({ name: 'style-quiz-style-clusters', params: { cluster: 'bohemian' } })
  }
}
</script>
